import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { register } from 'swiper/element/bundle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileFooterComponent } from './modules/my-profile/profile-footer/profile-footer.component';
import { GlobalErrorHandler } from './services/global-error-handler';
import { ReuseService } from './services/route-reuse.service';
import { SharedModule } from './shared/shared.module';
import { AllBadgesComponent } from './standalone-components/all-badges/all-badges.component';
import { NotificationsComponent } from './standalone-components/notifications/notifications.component';
import { SearchResultComponent } from './standalone-components/search-result/search-result.component';
import { SideBarComponent } from './standalone-components/side-bar/side-bar.component';

registerLocaleData(en);
register();

@NgModule({
    declarations: [AppComponent],
    imports: [
        NzLayoutModule,
        SideBarComponent,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        MonacoEditorModule.forRoot(),
        ProfileFooterComponent,
        NotificationsComponent,
        AllBadgesComponent,
        SearchResultComponent,
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        { provide: RouteReuseStrategy, useClass: ReuseService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
