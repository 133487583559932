import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberSplit',
})
export class NumberSplitPipe implements PipeTransform {
    transform(value: number, decimals = 6): { integer: string; decimal: string } {
        const integerPart = Math.floor(value).toString();
        const decimalPart = value.toFixed(decimals).split('.')[1];

        return {
            integer: integerPart,
            decimal: decimalPart,
        };
    }
}
