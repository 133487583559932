import { Pipe, PipeTransform } from '@angular/core';

interface PluralRule {
    singular: string;
    plural: string;
}

@Pipe({
    name: 'pluralize',
    standalone: true,
})
export class PluralizePipe implements PipeTransform {
    private specialRules: { [key: string]: PluralRule } = {
        person: { singular: 'person', plural: 'people' },
        child: { singular: 'child', plural: 'children' },
        // 可以添加更多特殊规则
    };

    transform(word: string, count: number): string {
        if (count === 1) {
            return word;
        }

        // 检查是否有特殊规则
        const rule = this.specialRules[word.toLowerCase()];
        if (rule) {
            return rule.plural;
        }

        // 默认加 s
        return `${word}s`;
    }
}
