<div *ngIf="notifications">
    <ng-container *ngFor="let item of notifications;let last = last">
        <div class="flex justify-start items-start gap-x-2 p-1 rounded-md cursor-pointer">
            <div class="flex-1 min-w-0 whitespace-break-spaces">
                <div class="font-normal text-sm">{{item.title}}</div>

                <div class="mt-1 text-sub-text text-xs">{{item.created_at | date:'HH:mm MMM dd, yyyy'}}</div>
            </div>
        </div>

        <div *ngIf="!last" class="divider"></div>
    </ng-container>

    <div *ngIf="notifications.length === 0" class="flex-center-center min-h-[200px] flex-col text-[#b5b5b5]">
        <img src="/assets/imgs/no-data/no-data.png" class="w-36" />
        <div class="text-sub-text text-sm text-center">No notification</div>
    </div>
</div>

<nz-skeleton *ngIf="!notifications" [nzActive]="true"></nz-skeleton>
