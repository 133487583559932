import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import localforage from 'localforage';
import { getTGDataFromWebApp, getTGSafeDataFromWebApp } from '../shared/utils/tg.util';
import { RequestService } from './request.service';
import { WalletStatusService } from './wallet-status.service';

interface TgUserData {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    language_code: string;
    allows_write_to_pm: boolean;
    photo_url?: string;
}

interface TGUnSafeUserData {
    query_id: string;
    user: TgUserData;
    auth_date: string;
    hash: string;
}

enum AccountType {
    ONLY_TG = 'tg',
    ONLY_WALLET = 'wallet',
    TG_WALLET = 'tg_wallet',
    NOT_CONNECTED = 'not_connected',
}

@Injectable({
    providedIn: 'root',
})
export class TelegramService {
    tgData: TGUnSafeUserData;
    private tgSafeData: any;

    get tgUserData() {
        return this.tgData?.user;
    }

    get isInTelegramWebApp() {
        return !!this.tgUserData;
    }

    get accountType(): AccountType {
        if (this.walletStatusService.isWalletConnected && this.isInTelegramWebApp) {
            return AccountType.TG_WALLET;
        }

        if (this.isInTelegramWebApp) {
            return AccountType.ONLY_TG;
        }

        if (this.walletStatusService.isWalletConnected) {
            return AccountType.ONLY_WALLET;
        }

        return AccountType.NOT_CONNECTED;
    }
    get isOnlyTg() {
        return this.accountType === AccountType.ONLY_TG;
    }
    get isOnlyWallet() {
        return this.accountType === AccountType.ONLY_WALLET;
    }
    get isTgWallet() {
        return this.accountType === AccountType.TG_WALLET;
    }
    get isNotConnected() {
        return this.accountType === AccountType.NOT_CONNECTED;
    }
    get isWalletConnected() {
        return this.accountType === AccountType.ONLY_WALLET || this.accountType === AccountType.TG_WALLET;
    }

    mooc = {
        query_id: 'AAGzhUlKAgAAALOFSUqqU3N9',
        user: {
            id: 5541299635,
            first_name: 'Lin',
            last_name: '',
            username: 'linxuan_li_96',
            language_code: 'en',
            allows_write_to_pm: true,
            photo_url: 'https://t.me/i/userpic/320/tBtey2iUDEYCVeNtIanGYbkqSR1miKuMFjl1WZRCFV-KJETBjNgX_yiRFtCkq734.svg',
        },
        auth_date: '1732534790',
        signature: 'GAr9fwtO-HkP9fRlF1-QLy7tuI5X_8SOPeSJqZrhw_AV3CBlDOQUUGq9UxSrGi8FOomL_KCXCuxRn6vy7833AA',
        hash: '8217713b684ebd604ae73a7763e70a9101f738cb31539783a3c4d5cfabc44abc',
    };

    private ready: Promise<void>;

    constructor(private router: Router, private walletStatusService: WalletStatusService, private requestService: RequestService) {
        this.ready = this.initialize();
        this.setupRouterChangeMonitor();
    }

    private async initialize(): Promise<void> {
        // 等待 SDK 加载
        while (!this.isTelegramWebAppAvailable()) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        this.setupUserInfo();
        this.setupBackButton();
    }

    private isTelegramWebAppAvailable(): boolean {
        return !!window.Telegram?.WebApp;
    }

    private async setupUserInfo() {
        const cachedTgData = localStorage.getItem('socialscan_tg_webapp_data');

        this.tgData = getTGDataFromWebApp();
        this.tgSafeData = getTGSafeDataFromWebApp();

        if (cachedTgData && !this.tgUserData) {
            this.tgData = JSON.parse(cachedTgData) as TGUnSafeUserData;
            localforage.setItem('socialscan_tg_webapp_data', JSON.stringify(this.tgData));
            return;
        }
    }

    private setupBackButton() {
        window.Telegram.WebApp.BackButton.onClick(() => {
            // TODO: back to previous page
            // if (this.walletStatusService.tokenStr) {
            this.router.navigate(['/my-profile']);
            // } else {
            // this.router.navigate(['/tg-landing']);
            // }
        });
    }

    private setupRouterChangeMonitor() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // if not in /my-profile page, show back button
                if (!event.url.startsWith('/my-profile')) {
                    window.Telegram.WebApp.BackButton.show();
                } else {
                    window.Telegram.WebApp.BackButton.hide();
                }
            }
        });
    }

    // 使用此方法确保 SDK 已加载
    async ensureReady(): Promise<void> {
        await this.ready;
    }

    connectWalletWithTG(data: { wallet_address: string; message: string; signature: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v1/socialscan/user/connect_wallet',
            data,
        });
    }

    loginWithTG(code?: string) {
        return this.requestService.sendRequest<{
            auth: string;
            registration_complete: true;
            telegram_id: string;
            user_id: string;
            wallet_address?: string;
        }>({
            method: 'POST',
            url: '/v1/socialscan/user/login_with_tg',
            data: {
                init_data: this.tgSafeData,
                ...(code && { code }),
            },
        });
    }

    bindTelegramToWallet(data: { id: number; first_name: string; username: string; auth_date: number; hash: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v1/socialscan/user/connect_telegram',
            data,
        });
    }
}
