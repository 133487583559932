export const environment = {
    production: false,
    campaignApiUrl: 'https://t6e6rpnymj.us-east-1.awsapprunner.com', // https://admin-api.w3w.ai
    dataApiUrl: 'https://data-api.lifo.ai',
    socialscanApiUrl: 'https://api-dev.socialscan.io',
    adminApiUrl: 'https://api-dev.w3w.ai',
    explorerUrl: 'https://zkevm.polygonscan.xyz',
    grecaptcha: {
        sitekey: '6Lczm-QgAAAAANEufUqi275YwAKN1L3BPuRAHgX0',
    },
    telegram: {
        id: 6453839259,
    },
    enableMessage: false,
    botName: 'hemera_socialscan_bot',
};
