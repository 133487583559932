import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackButtonDirective } from './back-button.directive';
import { CopyDirective } from './copy.directive';
import { EchartsDirective } from './echarts.directive';
import { ImgLoadSmoothDirective } from './img-load-smooth.directive';
import { LoadingDirective } from './loading.directive';
import { ScrollObserverDirective } from './scrollObserver.directive';

@NgModule({
    declarations: [BackButtonDirective, LoadingDirective, ScrollObserverDirective, ImgLoadSmoothDirective, EchartsDirective, CopyDirective],
    imports: [CommonModule],
    exports: [BackButtonDirective, LoadingDirective, ScrollObserverDirective, ImgLoadSmoothDirective, EchartsDirective, CopyDirective],
})
export class SocialscanDirectiveModule {}
