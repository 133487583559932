import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { environment } from '@src/environments/environment';
import { slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { filter } from 'rxjs';
import { CommonService } from './services/common.service';
import { EthersRequestService } from './services/ethers-request.service';
import { GtagService } from './services/gtag.service';
import { NotificationService } from './services/notification.service';
import { RouterChangeMonitorService } from './services/router-change-monitor.service';
import { TelegramService } from './services/telegram.service';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: any;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [slideInUpOnEnterAnimation({ duration: 300 }), slideOutDownOnLeaveAnimation({ duration: 300 })],
})
export class AppComponent implements AfterViewInit, OnInit {
    @ViewChild('scrollContainer') scrollContainer!: { elementRef: ElementRef<any> };

    sidebarVisible = false;

    sideBarCollapsed = false;

    globalModal: 'notification' | 'search' = null;

    scrollTop = 0;

    searchInputStr = '';

    searchResultVisible = false;

    isInMiningDaysPage = false;

    get isInTg() {
        return this.tgService.isInTelegramWebApp;
    }

    get notifications() {
        return this.notificationService.messages;
    }

    get hasUnreadNotifications() {
        return this.notifications?.some(n => !n.is_read);
    }

    constructor(
        private router: Router,
        private viewportScroller: ViewportScroller,
        @Inject(PLATFORM_ID) private platformId: any,
        private gtagService: GtagService,
        private routerMonitor: RouterChangeMonitorService,
        private ethersRequest: EthersRequestService,
        private notificationService: NotificationService,
        public commonService: CommonService,
        public tgService: TelegramService
    ) {}

    onScroll(ele: any) {
        this.scrollTop = ele.elementRef.nativeElement.scrollTop;
    }

    ngOnInit() {
        if (environment.production && isPlatformBrowser(this.platformId)) {
            this.gtagService.initializeGtag();
        }

        this.ethersRequest.initProvider();

        this.routerMonitor.setupRouterEvents();

        // 全局设置页面前进，后退时的恢复原先位置的动画
        this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(route => {
            this.sidebarVisible = false;
            const typedRoute: Scroll = route as Scroll;

            const currentPath = typedRoute.routerEvent?.url?.split('#')?.[0];
            const previousPath = this.routerMonitor.previousPath?.split('#')?.[0];

            if (currentPath === previousPath) {
                return;
            }

            if (typedRoute.position) {
                // Back
                setTimeout(() => {
                    this.scrollContainer.elementRef.nativeElement.scrollTo({
                        top: typedRoute.position[1],
                        left: typedRoute.position[0],
                        behavior: 'auto',
                    });
                }, 10);
            } else {
                // Forward
                setTimeout(() => {
                    this.scrollContainer.elementRef.nativeElement.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'auto',
                    });
                }, 10);
            }
        });

        // 监听路由事件, 设置页面信息
        this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe(event => {
            this.commonService.setPageInfo();

            // If Day Mining Page， collapsed side bar
            if (event?.url.startsWith('/hemera/agent/mining-days')) {
                this.sideBarCollapsed = true;
                this.isInMiningDaysPage = true;
            } else {
                this.sideBarCollapsed = false;
                this.isInMiningDaysPage = false;
            }
        });
    }

    ngAfterViewInit() {
        // Setup vh on mobile
        this.setupVH();
    }

    setupVH() {
        const setHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setHeight();
        window.addEventListener('resize', setHeight);
    }

    switchGlobalModal(modal: 'notification' | 'search') {
        if (this.globalModal === modal) {
            this.globalModal = null;
        } else {
            this.globalModal = modal;
        }
    }

    stopScroll(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    toggleSideBarCollapsed() {
        this.sideBarCollapsed = !this.sideBarCollapsed;

        setTimeout(() => {
            if (this.isInMiningDaysPage) {
                this.sideBarCollapsed = true;
            }
        }, 3000);
    }
}
