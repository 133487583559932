<div *ngIf="minting" class="!fixed z-50 left-0 top-0 w-screen h-screen bg-white bg-opacity-30" appLoading [loading]="true"></div>

<div class="cursor-pointer relative w-full flex-between-center gap-x-10">
    <div class="z-10 relative">
        <div *ngIf="!badge.is_earned && badge.points > 0" class="bg-black custom-badge">
            <img src="/assets/imgs/icon/sun-v2.png" class="w-4" alt="" />
            <div class="colorful-text font-semibold text-xs leading-[1] w-fit">{{badge.points}}</div>
        </div>

        <div *ngIf="!badge.is_earned && badge.section === 'DAY Mining'" class="bg-gradient custom-badge">
            <img src="/assets/imgs/hemera-round.png" class="w-4" alt="" />
            <div *ngIf="badge.id !== 'ranking'" class="gradient-color text-xs font-semibold leading-[1] w-fit">
                <ng-container *ngIf="badge.id === 'newbie'">60</ng-container>
                <ng-container *ngIf="badge.id === 'og'">120</ng-container>
            </div>
            <div *ngIf="badge.id === 'ranking'" class="text-xs font-semibold leading-[1] w-fit">
                <span class="gradient-color">720</span>
                <span class="gradient-color text-2xs"> Max</span>
            </div>
        </div>

        <div [nz-tooltip]="badge.description">
            <img
                [src]="badge.icon_enabled || '/assets/imgs/badges/follower-1000.png'"
                [alt]="badge.name"
                class="w-[64px] h-[64px] mx-auto"
                [class.not-owned]="!badge.is_earned"
            />
        </div>
    </div>

    <div class="flex-1 text-xs">
        <div>{{badge.name}}</div>

        <div
            *ngIf="badge.is_earned && badge.type !== 'onchain'"
            class="flex-start-center gap-x-1 mt-2"
            [ngClass]="{
                'on-chain-badge-holder': badge.type === 'onchain'
            }"
        >
            <span *ngIf="badge.type !== 'onchain'" class="text-[#F81]">{{badge.rarity | percent}} people</span>
            <ng-container *ngIf="badge.type === 'onchain'">
                <span>{{badge.user_count}} Holders </span>
                <span *ngIf="badge.is_earned" nz-icon nzType="antd:right-arrow-circle" class="text-base"></span>
            </ng-container>
        </div>
    </div>
</div>
