import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-invitation-email-popup',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './invitation-email-popup.component.html',
    styleUrls: ['./invitation-email-popup.component.less'],
})
export class InvitationEmailPopupComponent {
    readonly nzModalData = inject<{ invitationCode?: string }>(NZ_MODAL_DATA);

    confirming = false;
    invitationCode = '';
    email: string;

    invalidInvitationCode = false;
    invalidMsg: string;

    constructor(private router: Router, private walletStatusService: WalletStatusService, private modalRef: NzModalRef, private commonService: CommonService) {}

    completeSignUp() {
        this.confirming = true;
        this.walletStatusService
            .completeRegistration({
                user_id: this.walletStatusService.getUserId(),
                invitation_code: this.invitationCode,
                email: this.email,
            })
            .then(() => {
                // TODO: set new profile
                this.modalRef.close();
            })
            .catch(err => {
                if (err.status === 400) {
                    this.invalidInvitationCode = true;
                    this.invalidMsg = err.body.message;
                } else {
                    this.commonService.errorMessageByResponse(err);
                }
            })
            .finally(() => (this.confirming = false));
    }
}
