import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppKit, createAppKit } from '@reown/appkit';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { arbitrum, avalanche, baseSepolia, blast, bsc, linea, mainnet, mantle, optimism, polygon, scroll, zksync } from '@reown/appkit/networks';
import { useAppKitProvider } from '@reown/appkit/react';
import { LOCAL_STORAGE_KEY_ENUM } from '@shared/types/storage.type';
import { WalletEnum, WalletIconMap, WalletsEnableConnect } from '@shared/types/wallet-chain.type';
import { createIdenticon } from '@src/app/shared/utils/identicon';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, filter } from 'rxjs';
import { MyProfileService } from '../modules/my-profile/my-profile.service';
import { MyProfile } from '../shared/types/address.type';
import { isMobileBrowser } from '../shared/utils/browser';
import { CommonService } from './common.service';
import { GtagService } from './gtag.service';
import { NotificationService } from './notification.service';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class WalletStatusService {
    wallets = WalletsEnableConnect;
    walletIconMap = WalletIconMap;

    currentWallet = new BehaviorSubject<{
        wallet: WalletEnum;
        walletAddress: string;
        signature: string;
    }>(null);

    web3Modal: AppKit;

    tokenStr$ = new BehaviorSubject('');

    tokenExp: number; // milliseconds
    userId: string;
    signUpCompleted: boolean;

    selectedWalletChainId: string = null;

    userProfile$ = new BehaviorSubject<MyProfile>(null);

    msgToSign: string;

    prefix = 'did:pkh:eip155:1:';

    registrationCompleted = true;

    fetchingProfile = false;

    disconnecting = false;

    private defaultUserProfileImg: string;

    get walletType() {
        return this.currentWallet.getValue()?.wallet;
    }

    get tokenStr() {
        return this.tokenStr$.getValue();
    }

    get isWalletConnected() {
        return !!this.currentWallet.getValue()?.walletAddress;
    }

    get walletAddress() {
        return this.currentWallet.getValue()?.walletAddress;
    }

    get wallet() {
        return this.currentWallet.getValue()?.wallet;
    }

    get signature() {
        return this.currentWallet.getValue()?.signature;
    }

    get currentWalletIcon() {
        return this.walletIconMap.get(this.currentWallet.getValue()?.wallet);
    }

    get userProfileImg() {
        return this.userProfile?.profile_image || (this.walletAddress && this.commonService.getAddressIcon(this.walletAddress)) || '/assets/imgs/icon/user.png';
    }
    get userProfileName() {
        return this.commonService.getAddrName(this.userProfile) || this.walletAddress;
    }

    get userProfile() {
        return this.userProfile$.getValue();
    }

    set userProfile(value: MyProfile) {
        this.userProfile$.next(value);
    }

    constructor(
        private requestService: RequestService,
        private commonService: CommonService,
        private myProfileService: MyProfileService,
        private gtagService: GtagService,
        private notificationService: NotificationService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.generateWeb3Modal();
            this.setupInitialState();
            this.commonService.setAddressIconMap([this.walletAddress]);

            this.currentWallet.pipe(filter(data => !!data)).subscribe(async () => {
                if (!this.tokenStr) {
                    try {
                        await this.updateUserToken();
                    } catch (err) {
                        this.commonService.errorMessageByResponse(err as any);

                        setTimeout(() => {
                            this.disconnect();
                        }, 1000);
                    }
                }

                if (this.checkIfSignupCompleted() && !this.fetchingProfile) {
                    this.getWalletProfile();
                }
            });
        }
    }

    getUserId() {
        return this.userProfile?.id || this.decodeJWT(this.tokenStr$.getValue()).user_id;
    }

    setSessionData(data: { wallet?: WalletEnum; walletAddress?: string; signature?: string; isInTgWebApp?: boolean } = {}) {
        if ((data.walletAddress && data.wallet && data.signature) || (data.isInTgWebApp && data.wallet)) {
            localStorage.setItem(
                LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET,
                JSON.stringify({
                    wallet: data.wallet,
                    walletAddress: data.walletAddress,
                    signature: data.signature,
                })
            );
            this.currentWallet.next({
                wallet: data.wallet,
                walletAddress: data.walletAddress,
                signature: data.signature,
            });
            this.defaultUserProfileImg = createIdenticon(data.walletAddress ? data.walletAddress?.toLowerCase() : data.wallet);
            this.commonService.setAddressIconMap([data.walletAddress ? data.walletAddress : data.wallet]);
        } else {
            localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET);
            localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);
            this.currentWallet.next(null);
            this.userProfile = null;
            this.defaultUserProfileImg = null;
            this.tokenStr$.next(null);
        }
    }

    async disconnect() {
        this.disconnecting = true;
        if (this.wallet == WalletEnum.WALLET_CONNECT) {
            await this.web3Modal.disconnect();
        }

        this.setSessionData();

        if (window.location.pathname?.startsWith('/campaign/')) {
            window.location.reload();
        } else {
            window.location.href = '/';
        }
    }

    getWalletProfile() {
        this.fetchingProfile = true;

        return this.myProfileService
            .getUserProfile()
            .then(data => {
                this.userProfile$.next(data);
                this.signUpCompleted = true;

                // 只要更新了 walletProfile，就重新获取 badge/points
                this.myProfileService.getAllBadges();
                this.notificationService.getNotifications();

                if (data.earned_badges?.length) {
                    this.commonService.showGotNewBadgesModal(data.earned_badges);
                }

                this.gtagService.setUserID(data.id);

                return true;
            })
            .catch(err => {
                if (err.status == 401) {
                    this.commonService.error('Authentication expired. Please login again.');
                    this.disconnect();
                } else {
                    console.error(err);
                }

                return false;
            })
            .finally(() => (this.fetchingProfile = false));
    }

    setupInitialState() {
        const walletData = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET);
        const tokenStr = localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);

        if (tokenStr) {
            try {
                this.tokenStr$.next(tokenStr);
                this.tokenExp = this.decodeJWT(tokenStr)?.exp * 1000;

                if (this.tokenExp < Date.now()) {
                    this.disconnect();
                }
            } catch (er) {
                this.disconnect();
            }
        }

        if (walletData) {
            try {
                const { wallet, walletAddress, signature } = JSON.parse(walletData);
                this.currentWallet.next({
                    wallet,
                    walletAddress,
                    signature,
                });
                this.defaultUserProfileImg = createIdenticon((walletAddress as string).toLowerCase());
            } catch (er) {
                console.warn('Invalid wallet data in session storage');
            }
        }
    }

    completeRegistration(data: { user_id: string; invitation_code: string; email?: string }) {
        return this.requestService
            .sendRequest<{ auth: string }>({
                method: 'POST',
                url: '/v1/socialscan/user/complete_registration',
                data,
            })
            .then(res => {
                this.tokenStr$.next(res.auth);
                this.signUpCompleted = true;
                this.tokenExp = this.decodeJWT(res.auth)?.exp * 1000;
                localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TOKEN, res.auth);
                location.href = '/my-profile/dashboard';
            });
    }

    checkIfSignupCompleted() {
        if (!this.tokenStr$.getValue()) return false;
        try {
            const decodedInfo = this.decodeJWT(this.tokenStr$.getValue());
            this.registrationCompleted = decodedInfo?.registration_complete;
            return decodedInfo?.registration_complete;
        } catch (err) {
            return false;
        }
    }

    async generateWeb3Modal() {
        const projectId = 'c41d849166d6db9d1cea7fc2603554b6';

        const metadata = {
            name: 'SocialScan',
            description: 'SocialScan',
            url: 'https://socialscan.io',
            icons: ['https://socialscan.io/favicon.ico'],
        };

        this.web3Modal = createAppKit({
            adapters: [new EthersAdapter()],
            defaultNetwork: mainnet,
            allowUnsupportedChain: true,
            networks: [mainnet, bsc, polygon, arbitrum, optimism, avalanche, zksync, linea, blast, scroll, mantle, baseSepolia],
            metadata,
            projectId,
            features: {
                analytics: true, // Optional - defaults to your Cloud configuration
                socials: false,
                email: false,
            },
            themeMode: 'light',
        });
    }

    updateUserToken() {
        const currentWallet = this.currentWallet.getValue();
        if (currentWallet) {
            return this.requestService
                .sendRequest<{ auth: string; sdn_token: string; registration_complete: boolean; user_id: string }>({
                    method: 'POST',
                    url: '/v1/socialscan/user/login',
                    data: {
                        wallet_address: currentWallet.walletAddress,
                        message: this.msgToSign,
                        signature: currentWallet.signature,
                    },
                })
                .then(data => {
                    localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TOKEN, data.auth);
                    this.tokenStr$.next(data.auth);
                    this.tokenExp = this.decodeJWT(data.auth)?.exp * 1000;
                    this.signUpCompleted = data.registration_complete;
                });
        }
        return Promise.resolve(true);
    }

    decodeJWT(token: string): any {
        return jwtDecode(token);
    }

    detectWalletsBasedOnDevice() {
        if (isMobileBrowser()) {
            if (window.ethereum && (window.ethereum as any).isMetaMask) {
                return this.wallets.filter(item => item.name === WalletEnum.META_MASK);
            }

            if (window.ethereum && ((window.ethereum as any).isTrust || (window.ethereum as any).isTrustWallet)) {
                return this.wallets.filter(item => item.name === WalletEnum.TRUST_WALLET);
            }

            if (window.Telegram.WebApp?.initDataUnsafe?.user?.username) {
                return this.wallets.filter(item => item.name === WalletEnum.WALLET_CONNECT);
            }

            return [WalletEnum.WALLET_CONNECT, WalletEnum.TRUST_WALLET].map(item => this.wallets.find(wallet => wallet.name === item));
        } else {
            return this.wallets;
        }
    }

    getTrustWalletFromWindow() {
        const isTrustWallet = (ethereum: any) => {
            // Identify if Trust Wallet injected provider is present.
            const trustWallet = !!ethereum.isTrust;

            return trustWallet;
        };

        const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

        if (!injectedProviderExist) {
            return null;
        }

        if (isTrustWallet(window.ethereum)) {
            return window.ethereum;
        }

        if ((window.ethereum as any)?.providers) {
            return (window.ethereum as any).providers.find(isTrustWallet) ?? null;
        }

        return (window as any)['trustwallet'] ?? null;
    }

    getProvider(chainId?: number) {
        if (this.wallet == WalletEnum.TRUST_WALLET) {
            return this.getTrustWalletFromWindow();
        }

        if (this.wallet == WalletEnum.WALLET_CONNECT) {
            return this.web3Modal.getWalletProvider() || (useAppKitProvider('eip155')?.walletProvider as any);
        }

        if (this.wallet === WalletEnum.META_MASK) {
            return (window.ethereum as any)?.providers?.find((item: any) => item.isMetaMask) || window.ethereum;
        }

        if (this.wallet === WalletEnum.COINBASE) {
            // const coinbaseWallet = new CoinbaseWalletSDK({
            //     appName: 'SocialScan',
            //     darkMode: false,
            // });
            // return coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', chainId || 1);
        }

        return window.ethereum;
    }

    generateRandomHex(size: number) {
        let result = '';
        for (let i = 0; i < size; i++) {
            result += Math.floor(Math.random() * 256)
                .toString(16)
                .padStart(2, '0');
        }
        return result;
    }

    getMessageToSign() {
        return `Login with this account\n\ntime: ${new Date().toISOString()}\n${this.generateRandomHex(32)}`;
    }
}
