<svg class="w-full h-full relative" viewBox="0 0 44 44">
    <!-- 背景圆环 -->
    <circle cx="22" cy="22" r="18" fill="none" [attr.stroke]="bgColor" stroke-width="8" />
    <!-- 进度圆环 -->
    <circle
        cx="22"
        cy="22"
        r="18"
        fill="none"
        [attr.stroke]="progressColor"
        stroke-width="4"
        stroke-linecap="round"
        [attr.stroke-dasharray]="circumference"
        [attr.stroke-dashoffset]="-((100 - percentage) * circumference / 100)"
        transform="rotate(-90 22 22)"
        style="transition: stroke-dashoffset 0.3s ease"
    />
</svg>
