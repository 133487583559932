<div class="search-result">
    <ng-container *ngIf="data?.length; else noResultTemp">
        <div class="mt-2 overflow-y-auto">
            <!-- Address -->
            <div *ngIf="addressList?.length">
                <div *ngIf="!selectedTab" class="text-sm text-sub-text my-1.5">Address</div>
                <div
                    *ngFor="let item of addressList"
                    class="flex items-center p-1 cursor-pointer hover:bg-black hover:bg-opacity-6 rounded-md"
                    (click)="go('/address/'+item.wallet_address)"
                >
                    <app-hexagon-img class="w-8" [imgUrl]="item.profile_image || commonService.getAddressIcon(item.wallet_address)"></app-hexagon-img>
                    <div class="pl-2" class="flex-1 min-w-0 truncate">
                        <div *ngIf="item.ens_name"><span class="colorful-text font-medium">{{ item.ens_name }}</span></div>
                        <div class="text-sm w-full truncate">{{ item.wallet_address |address }}</div>
                    </div>
                </div>
            </div>

            <!-- Txn Hash -->
            <div *ngIf="txnList?.length">
                <div *ngIf="!selectedTab" class="text-sm text-sub-text my-1.5">Txn Hash</div>
                <div
                    *ngFor="let item of txnList"
                    class="flex items-center p-1 cursor-pointer hover:bg-black hover:bg-opacity-6 rounded-md"
                    (click)="go('/transaction/'+item.chain+'/'+item.transaction_hash)"
                >
                    <img src="/assets/imgs/txn-hash-icon.png" class="w-5 h-5" style="filter: contrast(0.1)" />
                    <img [src]="commonService.getChainImg(item.chain)" class="w-5 h-5 ml-1.5" />
                    <span class="ml-1.5 truncate" style="max-width: calc(100% - 55px)">{{ item.transaction_hash }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #noResultTemp>
    <div class="text-sm text-sub-text mt-5">No relevant data found. Please double check your input</div>
</ng-template>
