import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TelegramService } from '@src/app/services/telegram.service';
import { SharedModule } from '@src/app/shared/shared.module';

@Component({
    selector: 'app-profile-footer',
    templateUrl: './profile-footer.component.html',
    styleUrls: ['./profile-footer.component.less'],
    standalone: true,
    imports: [CommonModule, SharedModule],
})
export class ProfileFooterComponent implements OnInit {
    @Input() navTab: 'home' | 'profile' | 'mining' = 'home';

    highlightVisible = false;

    constructor(private router: Router, private tgService: TelegramService) {}

    ngOnInit() {
        const miningHighlighShown = localStorage.getItem('socialscan_miningHighlighShown');

        if (this.tgService.isInTelegramWebApp && !miningHighlighShown) {
            this.highlightVisible = true;
            localStorage.setItem('socialscan_miningHighlighShown', '1');
        }
    }

    changeNavTab(tab: 'home' | 'profile' | 'mining') {
        if (tab === 'mining') {
            this.router.navigateByUrl('/hemera/agent/mining-days');
        } else {
            this.router.navigate([], { fragment: tab });
        }
    }
}
