import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { NotificationService } from '@src/app/services/notification.service';
import { UserService } from '@src/app/services/user.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { SharedModule } from '@src/app/shared/shared.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ProgressCircleComponent } from '../progress-circle/progress-circle.component';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
    selector: 'app-side-bar',
    standalone: true,
    imports: [CommonModule, SharedModule, NzModalModule, ProgressCircleComponent, NotificationsComponent, NzDropDownModule, SearchResultComponent],
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.less'],
})
export class SideBarComponent {
    steps = [
        'Create an account',
        'Browse dashboard or AI agents for insights',
        'Track your Web3, Crypto, and DeFi portfolio',
        'Join UDF Mining game for rewards',
    ];

    completedSteps: number[] = Array(this.steps.length).fill(null);

    getStartedVisible = false;
    notfiVisible = false;
    searchDropDownVisible = false;

    searchVal = '';

    get stepTitle() {
        return `Step ${this.firstUncompletedStep} of ${this.steps.length}`;
    }

    get firstUncompletedStep() {
        return this.completedSteps.findIndex(step => !step) + 1 || this.steps.length;
    }

    get notifications() {
        return this.notificationService.messages;
    }

    get percentage() {
        return (this.completedSteps.filter(Boolean).length / this.steps.length) * 100;
    }

    get hasUnreadNotifications() {
        return this.notifications?.some(n => !n.is_read);
    }

    constructor(
        private commonService: CommonService,
        public walletStatusService: WalletStatusService,
        public userService: UserService,
        private notificationService: NotificationService
    ) {
        // TODO: Save Step to localStorage
        this.completedSteps[0] = 1;
        setTimeout(() => {
            this.completedSteps[1] = 1;
        }, 2000);
    }

    toggleWalletConnection() {
        if (this.walletStatusService.isWalletConnected) {
            this.walletStatusService.disconnect();
        } else {
            this.commonService.showLogInModal();
        }
    }

    searched() {
        this.searchDropDownVisible = true;
    }
}
