import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BadgeComponent } from '@src/app/shared/components/badge/badge.component';
import { SharedModule } from '@src/app/shared/shared.module';

@Component({
    selector: 'app-flat-badge',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './flat-badge.component.html',
    styleUrls: ['./flat-badge.component.less'],
})
export class FlatBadgeComponent extends BadgeComponent {}
