<nav class="flex flex-col h-full gap-y-10 px-6 py-10 min-w-[240px] whitespace-nowrap">
    <a [routerLink]="['/']">
        <img src="/assets/imgs/logo/v2-socialscan.png" alt="logo" class="w-auto h-[37px]" />
    </a>
    <div class="flex-1 min-h-0 flex flex-col gap-y-4">
        <div
            class="hidden md:block"
            nz-dropdown
            [nzDropdownMenu]="searchDropdown"
            [nzPlacement]="'bottomLeft'"
            [(nzVisible)]="searchDropDownVisible"
            [nzTrigger]="null"
            [nzOverlayClassName]=""
            [nzOverlayStyle]="{
                'padding': '0 16px 16px',
                'border-radius': '8px',
                'background': '#fff',
                'box-shadow':'0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)'
            }"
        >
            <ng-template #prefixTemplateUser><span nz-icon nzType="antd:v2-search" class="text-2xl" nzTheme="outline"></span></ng-template>

            <nz-input-group [nzPrefix]="prefixTemplateUser">
                <input type="text" nz-input placeholder="Search address/Txn hash" [(ngModel)]="searchVal" />
            </nz-input-group>
        </div>

        <nz-dropdown-menu #searchDropdown="nzDropdownMenu">
            <app-search-result [inputStr]="searchVal" (fetchedData)="searched()"></app-search-result>
        </nz-dropdown-menu>

        <div class="overflow-y-auto flex-1 w-full small-scrollbar space-y-1">
            <div [ngClass]="['nav-item']" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <span nz-icon nzType="antd:v2-home" class="text-2xl" nzTheme="outline"></span>
                <span>Home</span>
            </div>

            <div [ngClass]="['nav-item']">
                <span nz-icon nzType="antd:v2-analysis" class="text-2xl" nzTheme="outline"></span>
                <span>UDF Dashboard</span>
            </div>

            <div [ngClass]="['nav-sub-item']">
                <div class="w-6"></div>
                <span>FBTC</span>
            </div>

            <div
                [ngClass]="['nav-item']"
                [routerLink]="['/','hemera','agent','mining-days']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }"
            >
                <span nz-icon nzType="antd:v2-game" class="text-2xl" nzTheme="outline"></span>
                <span>UDF Mining Game</span>
            </div>

            <div [ngClass]="['nav-item']">
                <span nz-icon nzType="antd:v2-robot" class="text-2xl" nzTheme="outline"></span>
                <span>AI Agent</span>
            </div>

            <div [ngClass]="['nav-sub-item']">
                <div class="w-6"></div>
                <span>On Chain Intelligence</span>
            </div>

            <div class="divider"></div>

            <div [ngClass]="['nav-item']" [routerLink]="['/my-profile']" [routerLinkActive]="'active'">
                <span nz-icon nzType="antd:v2-user" class="text-2xl" nzTheme="outline"></span>
                <span>Profile</span>
            </div>

            <div [ngClass]="['nav-item']" [routerLink]="['/referral']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <span nz-icon nzType="antd:v2-share" class="text-2xl" nzTheme="outline"></span>
                <span>Referral</span>
            </div>
        </div>
    </div>
    <div>
        <!-- Get Started -->
        <button
            *ngIf="walletStatusService.isWalletConnected && percentage !== 100"
            nz-button
            class="get-started-btn"
            nz-popover
            [(nzPopoverVisible)]="getStartedVisible"
            [nzPopoverTitle]="stepTitleTemplate"
            [nzPopoverContent]="stepTemplate"
            nzPopoverPlacement="right"
            nzPopoverTrigger="click"
        >
            <app-progress-circle [percentage]="percentage" class="w-[24px] h-[24px]"></app-progress-circle>
            <div>Get Started</div>
            <span nz-icon nzType="caret-right" class="text-sm" nzTheme="outline"></span>
        </button>

        <button
            *ngIf="!walletStatusService.isWalletConnected"
            nz-button
            nzType="primary"
            class="connect-wallet-btn"
            nzSize="large"
            (click)="toggleWalletConnection()"
        >
            <div class="w-fit colorful-text">Connect Wallet</div>
            <img src="/assets/imgs/v2-long-right-arrow-colorful.png" alt="go" class="w-6 h-6" />
        </button>

        <button
            *ngIf="walletStatusService.isWalletConnected"
            nz-button
            nzType="primary"
            class="connect-wallet-btn"
            nzSize="large"
            [routerLink]="['/my-profile']"
        >
            <app-hexagon-img class="w-8" [imgUrl]="userService.userImg"></app-hexagon-img>
            <div class="flex-1 text-left">{{ userService.userName | address}}</div>
            <span
                *ngIf="!walletStatusService.disconnecting"
                nz-icon
                nzType="antd:v2-logout"
                class="text-2xl text-white opacity-30"
                nzTheme="outline"
                (click)="toggleWalletConnection();$event.stopPropagation()"
            ></span>
            <span *ngIf="walletStatusService.disconnecting" class="text-white text-2xl" nz-icon nzType="loading" nzTheme="outline"></span>
        </button>
    </div>

    <div class="flex-between-center gap-x-5">
        <div class="flex-start-center gap-x-5">
            <a href="https://discord.gg/ZqYx5dXsAh" target="_blank" class="social-icon">
                <span nz-icon nzType="antd:discord" nzTheme="fill" class="text-lg"></span>
            </a>

            <a href="https://twitter.com/socialscan_io" target="_blank" class="social-icon">
                <span nz-icon nzType="antd:x" nzTheme="fill" class="text-base"></span>
            </a>
        </div>

        <button
            *ngIf="walletStatusService.isWalletConnected"
            nz-button
            nzShape="round"
            nzSize="small"
            nz-popover
            class="!w-8 !h-8 !border-none max-md:hidden relatiiive"
            [nzPopoverTitle]="notifiTitleTemplate"
            [nzPopoverContent]="notifTemplate"
            nzPopoverPlacement="right"
            nzPopoverTrigger="click"
            [nzPopoverOverlayStyle]="{ maxWidth: '460px' }"
            [nzPopoverArrowPointAtCenter]="false"
            [(nzPopoverVisible)]="notfiVisible"
        >
            <span nz-icon nzType="antd:v2-notif" class="text-lg"></span>
            <div *ngIf="hasUnreadNotifications " class="absolute w-2 h-2 rounded-full bg-[#FF462A] right-0 top-0"></div>
        </button>
    </div>
</nav>

<!-- Templates -->
<ng-template #stepTemplate>
    <div>
        <div *ngFor="let step of steps; let i = index" class="flex-start-center gap-x-2.5 text-sm py-1">
            <span nz-icon class="text-base" [nzType]="completedSteps[i] ? 'antd:v2-checked' : 'antd:v2-unchecked'"></span>
            <span>{{ i + 1 }}. {{ step }}</span>
        </div>
    </div>
</ng-template>

<ng-template #stepTitleTemplate>
    <div class="w-full flex-between-center">
        <div>{{stepTitle}}</div>

        <span nz-icon nzType="antd:v2-close" class="text-base !text-[#999]" (click)="getStartedVisible = false"></span>
    </div>
</ng-template>

<ng-template #notifTemplate>
    <div class="max-h-[500px] overflow-y-auto small-scrollbar text-sm">
        <app-notifications></app-notifications>
    </div>
</ng-template>

<ng-template #notifiTitleTemplate>
    <div class="w-full flex-between-center">
        <div>Notifications</div>

        <span nz-icon nzType="antd:v2-close" class="text-base !text-[#999]" (click)="notfiVisible = false"></span>
    </div>
</ng-template>
