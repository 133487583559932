import { Injectable } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { AllBadgesComponent } from '@src/app/standalone-components/all-badges/all-badges.component';
import { PointsHistoryComponent } from '@src/app/standalone-components/points-history/points-history.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root',
})
export class MyProfilePopupService {
    constructor(
        private modalService: NzModalService,
        private drawerService: NzDrawerService,
        private commonService: CommonService,
        private walletStatusService: WalletStatusService
    ) {}

    showPointsHistory(type: string = null, points = 0) {
        if (this.commonService.isMobile) {
            this.drawerService.create({
                nzContent: PointsHistoryComponent,
                nzTitle: `${type ? 'Referral points record' : `Points Record (${this.walletStatusService.userProfile?.points || points})`}`,
                nzFooter: null,
                nzPlacement: 'bottom',
                nzContentParams: { type },
                nzHeight: 'auto',
                nzClosable: true,
            });
        } else {
            const ref = this.modalService.create({
                nzTitle: `${type ? 'Referral points record' : `Points Record (${this.walletStatusService.userProfile?.points || points})`}`,
                nzFooter: null,
                nzContent: PointsHistoryComponent,
                nzWidth: '480px',
                nzCentered: true,
            });

            ref.getContentComponent().type = type;
        }
    }

    showAllBadges() {
        this.modalService.create({
            nzTitle: 'All badges',
            nzFooter: null,
            nzContent: AllBadgesComponent,
            nzWidth: '660px',
            nzCentered: true,
        });
    }
}
