import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { copy } from '@shared/utils/common.util';
import { CommonService } from '@src/app/services/common.service';

@Directive({
    selector: '[appCopy]',
})
export class CopyDirective {
    @Input() copyText = '';
    @Input() reminderMsg = 'Copied!';
    @HostBinding('style.cursor') cursor = 'pointer';

    constructor(private commonService: CommonService) {}

    @HostListener('click')
    onClick() {
        copy(this.copyText).then(() => this.commonService.success(this.reminderMsg));
    }
}
