import { Injectable } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { RequestService } from '@src/app/services/request.service';
import { Holding20, MyProfile } from '@src/app/shared/types/address.type';
import { BadgeApiInfo } from '@src/app/shared/types/badge.type';
import { PageApiData } from '@src/app/shared/types/common.type';
import { PointTaskApiInfo } from '@src/app/shared/types/point-tasks.type';
import { orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MyProfileService {
    badges$ = new BehaviorSubject<BadgeApiInfo[]>(null);
    badgesBySection: {
        section: string;
        badges: BadgeApiInfo[];
    }[];

    pointsHistory: PointTaskApiInfo[];

    assetsInfo: {
        erc20Tokens: Holding20[];
        netWorth: number;
    } = {
        erc20Tokens: [],
        netWorth: 0,
    };

    get ownedBadgeCount() {
        return this.badges$.getValue()?.filter(item => item.is_earned).length || 0;
    }

    get validOnChainBadges() {
        return this.badges$.getValue()?.filter(item => !item.is_earned && item.section === 'Onchain');
    }

    constructor(private requestService: RequestService, private commonService: CommonService) {}

    getUserProfile() {
        return this.requestService.sendRequest<MyProfile>({
            method: 'GET',
            url: '/v1/socialscan/user/profile',
        });
    }

    updateUserProfile(data: Partial<MyProfile>) {
        return this.requestService
            .sendRequest<MyProfile>({
                method: 'POST',
                url: '/v1/socialscan/user/profile',
                data,
            })
            .then(profile => {
                if (profile.earned_badges?.length) {
                    this.commonService.showGotNewBadgesModal(profile.earned_badges);
                }

                return profile;
            });
    }

    getFollowers(page = 1, size = 10) {
        return this.requestService.sendRequest<PageApiData<any>>({
            method: 'GET',
            url: `/v1/socialscan/user/followers?page=${page}&size=${size}`,
        });
    }

    getFollowings(page = 1, size = 10) {
        return this.requestService.sendRequest<PageApiData<any>>({
            method: 'GET',
            url: `/v1/socialscan/user/followings?page=${page}&size=${size}`,
        });
    }

    getAllBadges() {
        return this.requestService
            .sendRequest<{ badges: BadgeApiInfo[] }>({
                method: 'GET',
                url: '/v1/socialscan/user/badges',
            })
            .then(data => {
                const allBadges = orderBy(data.badges, ['is_earned', 'earned_at', 'rarity'], ['desc', 'desc', 'desc']);

                const badgesBySection: { [key: string]: BadgeApiInfo[] } = {};
                allBadges.forEach(badge => {
                    if (badgesBySection[badge.section]) {
                        badgesBySection[badge.section].push(badge);
                    } else {
                        badgesBySection[badge.section] = [badge];
                    }
                });
                this.badgesBySection = Object.entries(badgesBySection)
                    .map(([section, badges]) => ({ section, badges }))
                    .sort(a => {
                        if (a.section === 'DAY Mining') return -1;
                        return 0;
                    });

                this.badges$.next(allBadges);
            });
    }

    getPointsHistory(params: { page: number; size: number; type?: string }) {
        return this.requestService.sendRequest<PageApiData<PointTaskApiInfo> & { total_points: number }>({
            method: 'GET',
            url: `/v1/socialscan/user/points?page=${params.page}&size=${params.size}${params.type ? `&type=${params.type}` : ''}`,
        });
    }

    /**
     *
     * @param wallet_address
     * @param followed_wallet_address
     * @description wallet_address follow followed_wallet_address
     */
    followUser(wallet_address: string, followed_wallet_address: string) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v1/socialscan/user/follow',
            data: { wallet_address, followed_wallet_address },
        });
    }

    refreshAssets() {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/v2/socialscan/user/token_holdings_refresh',
            data: null,
        });
    }

    getNewRetroactiveCard() {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/v1/socialscan/user/retroactive_card',
            data: null,
        });
    }

    getCode() {
        return this.requestService.sendRequest<{ code: string; max_usage: number; used: number }[]>({
            method: 'GET',
            url: '/v1/socialscan/user/invitation/code',
        });
    }

    getCodeHistory() {
        return this.requestService.sendRequest<{ data: { user_id: string; wallet_address: string; display_name: string }[]; total: number }>({
            method: 'GET',
            url: '/v1/socialscan/user/invitation/history',
        });
    }

    setAssetsInfo(data: { holdings: any[] }) {
        this.assetsInfo.erc20Tokens = data.holdings.filter(item => item.balance > 0).sort((a, b) => b.balance * b.price - a.balance * a.price);

        this.assetsInfo.netWorth = this.assetsInfo.erc20Tokens.reduce((pre, cur) => pre + (cur.balance * cur.price || 0), 0);
    }
}
