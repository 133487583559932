import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@src/app/services/common.service';
import { RequestService } from '@src/app/services/request.service';
import { SharedModule } from '@src/app/shared/shared.module';
import { ChainEnum, getChainSymbolIconByChainName } from '@src/app/shared/types/wallet-chain.type';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

export interface AddressItem {
    wallet_address: string;
    ens_name: string;
    type: string;
    is_following: boolean;
    is_follower: boolean;
    allow_follower: boolean;
    display_name: string;
    profile_image: string;
    followers_count: number;
    followings_count: number;
}

export interface TxnItem {
    transaction_hash: string;
    chain: ChainEnum;
    type: string;
}

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.less'],
    standalone: true,
    imports: [CommonModule, SharedModule],
})
export class SearchResultComponent implements OnChanges, OnDestroy {
    @Input() inputStr: string;
    @Output() fetchedData = new EventEmitter<any[]>();

    data: any[];

    getChainSymbolIconByChainName = getChainSymbolIconByChainName;

    selectedTab: 'address' | 'transaction' = null;
    addressList: AddressItem[];
    txnList: TxnItem[];

    inputStr$ = new Subject<string>();
    search$: Subscription;
    destroy$ = new Subject();

    constructor(public commonService: CommonService, private router: Router, private requestService: RequestService) {}

    ngOnChanges(e: SimpleChanges): void {
        this.inputStr$.next(e['inputStr'].currentValue);
    }

    ngOnInit() {
        this.inputStr$
            .pipe(
                debounceTime(200),
                switchMap(value => {
                    return new Promise(resolve => {
                        if (value) {
                            if (this.search$ && !this.search$.closed) {
                                this.search$.unsubscribe();
                            }

                            const allCompleted$ = new Subject();

                            this.search$ = combineLatest([this.search(value)])
                                .pipe(takeUntil(allCompleted$))
                                .subscribe({
                                    next: data => {
                                        resolve(data[0]);
                                    },
                                    error: () => resolve([]),
                                    complete: () => {
                                        allCompleted$.next(null);
                                        allCompleted$.complete();
                                    },
                                });
                        } else {
                            resolve(null);
                        }
                    });
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((res: any) => {
                this.data = res || [];
                this.addressList = this.data.filter(item => item.type === 'address');
                this.txnList = this.data.filter(item => item.type === 'transaction');
                if (this.data.length) {
                    this.fetchedData.emit(this.data);
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    go(link: string) {
        setTimeout(() => {
            this.router.navigateByUrl(link);
        }, 10);
    }

    search(searchVal: string) {
        return this.requestService.sendRequest$<any[]>({
            method: 'GET',
            url: `/v1/socialscan/inscription/search?q=${searchVal}`,
        });
    }
}
