<div class="h-20 w-full flex justify-between items-stretch bg-[#010101] pt-2.5 pb-5 border-t-[#353537] border-t-[1px] z-30">
    <div class="flex-center-center flex-1 brightness-50" [routerLink]="['/']" [routerLinkActive]="'active'">
        <img src="/assets/imgs/mining/home-v2.png" class="h-8 w-auto" alt="" />
    </div>

    <div class="flex-center-center flex-1 brightness-50" [routerLink]="['/','hemera','agent','mining-days']" [routerLinkActive]="'active'">
        <img
            src="/assets/imgs/mining/hoe-v2.png"
            class="w-8 h-8"
            [ngStyle]="{
                opacity: highlightVisible ? 0 : 1
            }"
        />
    </div>
    <div class="flex-center-center flex-1 brightness-50" [routerLink]="['/','my-profile']" [routerLinkActive]="'active'">
        <img src="/assets/imgs/mining/user-v2.png" class="w-8 h-8" alt="" />
    </div>
</div>

<div *ngIf="highlightVisible" class="fixed w-screen h-screen bg-black bg-opacity-70 left-0 top-0" (click)="highlightVisible = false">
    <div class="absolute bottom-0 w-full flex-center-center pt-2.5 pb-5 h-20">
        <img src="/assets/imgs/mining/hoe-v2-white.png" class="w-8 h-8" alt="" />

        <div class="absolute top-0 left-1/2 -translate-y-full -translate-x-1/2 w-fit flex-center-center">
            <img src="/assets/imgs/mining/mining-highlight-bubble.png" class="w-[92px] h-auto" alt="" />
            <div class="absolute text-sm top-[7px] left-[23px]">Mining</div>
        </div>
    </div>
</div>
