import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { MyProfileService } from '@src/app/modules/my-profile/my-profile.service';
import { CommonService } from '@src/app/services/common.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { FlatBadgeComponent } from '@src/app/standalone-components/flat-badge/flat-badge.component';
import dayjs from 'dayjs';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';
import { BadgeInfoComponent } from '../../shared/components/badge-info/badge-info.component';
import { SharedModule } from '../../shared/shared.module';
import { getAge } from '../../shared/utils/common.util';

@Component({
    selector: 'app-all-badges',
    templateUrl: './all-badges.component.html',
    styleUrls: ['./all-badges.component.less'],
    standalone: true,
    imports: [CommonModule, SharedModule, BadgeInfoComponent, FlatBadgeComponent],
})
export class AllBadgesComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<void>();

    isMiningCampaignEnded = false;

    campaignEndDate = dayjs('2024-09-30');

    restDays: string;

    get userProfile() {
        return this.walletStatusService.userProfile;
    }

    constructor(
        private walletStatusService: WalletStatusService,
        private router: Router,
        public myProfileService: MyProfileService,
        public commonService: CommonService,
        @Optional() private modalRef: NzModalRef,
        @Optional() private drawerRef: NzDrawerRef
    ) {}

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.modalRef?.close();
            this.drawerRef?.close();
        });

        this.isMiningCampaignEnded = dayjs().isAfter(this.campaignEndDate);

        if (this.isMiningCampaignEnded) {
            this.restDays = 'Campaign Ended.';
        } else {
            this.restDays = getAge(this.campaignEndDate.toString());
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
