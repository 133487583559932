<div
    class="flex flex-col relative"
    id="socialscanapp"
    [ngClass]="{
        'h-[calc(var(--vh,1vh)*100)]': !tgService.isInTelegramWebApp,
        'h-[var(--tg-viewport-stable-height)]': tgService.isInTelegramWebApp
    }"
>
    <nz-layout>
        <nz-sider
            nzWidth="288px"
            nzTheme="light"
            class="max-md:!hidden"
            nzBreakpoint="md"
            nzCollapsible
            [nzCollapsedWidth]="60"
            [(nzCollapsed)]="sideBarCollapsed"
            [nzTrigger]="null"
        >
            <app-side-bar *ngIf="!sideBarCollapsed"></app-side-bar>
            <div *ngIf="sideBarCollapsed" class="h-full w-full flex-center-center">
                <img src="/assets/imgs/v2-collapse.png" class="w-8 h-8 cursor-pointer" alt="" (click)="toggleSideBarCollapsed()" />
            </div>
        </nz-sider>

        <nz-layout>
            <!-- 移动端 header -->
            <nz-header class="md:hidden">
                <ng-container *ngIf="!isInTg">
                    <div class="flex-1">
                        <div class="nav-icon" (click)="sidebarVisible = true">
                            <span nz-icon nzType="menu"></span>
                        </div>
                    </div>
                </ng-container>

                <span *ngIf="tgService.isOnlyTg" class="text-[40px]" nz-icon nzType="antd:v2-wallet" (click)="commonService.showLogInModal()"></span>

                <img *ngIf="!tgService.isOnlyTg" src="/assets/imgs/logo/v2-socialscan-dark.png" alt="SocialScan" class="h-[36px]" />

                <div class="flex-end-center gap-x-4 flex-1">
                    <div class="nav-icon relative">
                        <span
                            nz-icon
                            [nzType]="globalModal === 'notification' ? 'antd:v2-close' : 'antd:v2-notif'"
                            (click)="switchGlobalModal('notification')"
                        ></span>
                        <div *ngIf="hasUnreadNotifications " class="absolute w-2 h-2 rounded-full bg-[#FF462A] right-0 top-0"></div>
                    </div>

                    <div class="nav-icon">
                        <span nz-icon [nzType]="globalModal === 'search' ? 'antd:v2-close' : 'antd:v2-search'" (click)="switchGlobalModal('search')"></span>
                    </div>
                </div>
            </nz-header>

            <nz-content class="relative" [ngClass]="{'!p-0': isInMiningDaysPage}" #scrollContainer (scroll)="onScroll(scrollContainer)">
                <main><router-outlet></router-outlet></main>

                <!-- 移动端 notification -->
                <div
                    *ngIf="globalModal === 'notification'"
                    [@slideInUpOnEnter]
                    [@slideOutDownOnLeave]
                    class="absolute inset-0 w-full h-full bg-white z-50 overflow-y-auto p-4"
                    (wheel)="stopScroll($event)"
                    (touchmove)="stopScroll($event)"
                    [style.top.px]="scrollTop"
                >
                    <div class="sticky top-0 left-0">
                        <div class="font-semibold text-xl">Notifications</div>

                        <div class="divider"></div>
                    </div>

                    <app-notifications></app-notifications>
                </div>

                <!-- 移动端 search 组件 -->
                <div
                    *ngIf="globalModal === 'search'"
                    [@slideInUpOnEnter]
                    [@slideOutDownOnLeave]
                    class="absolute inset-0 w-full h-full bg-white z-50"
                    (wheel)="stopScroll($event)"
                    (touchmove)="stopScroll($event)"
                    [style.top.px]="scrollTop"
                >
                    <div class="p-4">
                        <input nz-input placeholder="Search address/Txn hash" [(ngModel)]="searchInputStr" class="!h-[44px] !rounded-3xl !bg-badge" />

                        <app-search-result
                            [hidden]="!searchResultVisible"
                            [inputStr]="searchInputStr"
                            (fetchedData)="searchResultVisible = true"
                        ></app-search-result>
                    </div>
                </div>
            </nz-content>

            <nz-footer *ngIf="isInTg">
                <app-profile-footer></app-profile-footer>
            </nz-footer>
        </nz-layout>
    </nz-layout>

    <!-- 移动端 H5 侧拉 -->
    <nz-drawer
        [nzClosable]="false"
        [nzVisible]="sidebarVisible"
        nzPlacement="left"
        [nzTitle]="null"
        (nzOnClose)="sidebarVisible = false"
        [nzWidth]="320"
        [nzBodyStyle]="{
            'background': 'linear-gradient(to bottom, #c9fffc, #ffeba3)',
            'padding': '0'
        }"
    >
        <ng-container *nzDrawerContent>
            <app-side-bar></app-side-bar>
        </ng-container>
    </nz-drawer>
</div>
