import { CommonModule } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { MyProfilePopupService } from '@src/app/modules/my-profile/my-profile-popup.service';
import { environment } from '@src/environments/environment';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { SharedModule } from '../../shared.module';
import { BadgeApiInfo } from '../../types/badge.type';

@Component({
    selector: 'app-badge-info',
    templateUrl: './badge-info.component.html',
    styleUrls: ['./badge-info.component.less'],
    standalone: true,
    imports: [CommonModule, SharedModule],
})
export class BadgeInfoComponent {
    @Input() badges: BadgeApiInfo[];
    @Input() additionalPoints = 0;
    @Input() additionalInvitationCodes = 0;

    get totalPoints() {
        return this.additionalPoints + this.badges?.reduce((acc, badge) => acc + badge.points, 0) || 0;
    }

    get gottenConnectTwitterBadge() {
        return this.badges?.some(badge => badge.id === 'connect_twitter');
    }

    constructor(
        private router: Router,
        private myProfilePopupService: MyProfilePopupService,
        @Optional() private drawerRef: NzDrawerRef,
        @Optional() private modalRef: NzModalRef
    ) {}

    shareToTwitter() {
        const badgeTwitterName = this.badges[0].name
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join('');

        let badgeType = 'badge';
        if (this.badges[0].section === 'Onchain') {
            badgeType = 'NFT+badge';
        }

        window.open(
            `https://twitter.com/intent/tweet?text=I+just+claimed+an+awesome+%23${badgeTwitterName}+${badgeType}+on+socialscan.io!+Join+me+in+the+fun+now!+%F0%9F%8C%9E+%23ScanAnything`
        );
    }

    showAllBadges() {
        this.myProfilePopupService.showAllBadges();

        this.close();
    }

    close() {
        this.modalRef?.triggerOk();
        this.drawerRef?.close();
    }

    goLineaDataScanner() {
        if (environment.production) {
            this.router.navigate(['/community/contract/linea/0x780de722234532f7d61ca3d147574f44a85c4244']);
        } else {
            this.router.navigate(['/community/contract/linea-testnet/0x4ff8f1cf513f0f4b69c946dcf93d82a9f8cc6f56']);
        }
        this.close();
    }

    goUltiveserAIDay() {
        this.router.navigate(['/community/contract/polygon/0x1c682a755dc820a0e63de7b5b38c55e311cf15e2']);
        this.close();
    }
}
