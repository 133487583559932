import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-circle',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './progress-circle.component.html',
    styleUrls: ['./progress-circle.component.less'],
})
export class ProgressCircleComponent {
    // 10, 20, 30, 40, 50...
    @Input() percentage = 0;
    @Input() bgColor = 'rgba(0, 0, 0, 0.1)';
    @Input() progressColor = 'rgba(0, 0, 0, 1)';

    circumference: number = 2 * Math.PI * 18;
}
