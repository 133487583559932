<div class="p-0.5">
    <div>
        <input nz-input type="text" id="email" placeholder="What’s your email address? (Optional)" class="mb-2" />
        <br />
        <label for="email">For announcement purposes.</label>
    </div>
    <div class="mt-6">
        <input nz-input type="text" id="email" placeholder="Invitation Code (Optional)" class="mb-2" [nzStatus]="invalidInvitationCode ? 'error' : null" />
        <div *ngIf="invalidInvitationCode" class="text-sm mb-5 text-error">{{invalidMsg}}</div>
        <br />
        <label for="email">Join our Discord for invitation code.</label>
    </div>

    <button nz-button class="mt-2">
        <span nz-icon nzType="antd:discord" nzTheme="fill" class="text-2xl"></span>
        <span>Discord</span>
    </button>

    <button nz-button nzType="primary" class="mt-8" (click)="completeSignUp()" [nzLoading]="confirming">
        <div class="colorful-text">Confirm</div>
        <img *ngIf="!confirming" src="/assets/imgs/v2-long-right-arrow-colorful.png" class="w-6 h-6" alt="" />
    </button>
</div>
