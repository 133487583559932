<div class="flex flex-col justify-start items-stretch gap-y-2.5 font-bold w-full">
    <div *ngIf="tgService.isOnlyTg" class="px-4 py-2 rounded-xl text-sm text-[#2990FFE5] bg-[#2990FF26] font-normal">
        Please connect a new wallet. Ensure this wallet has not been previously connected or binded with SocialScan.Otherwise, it will result in a connection
        failure.
    </div>

    <div
        *ngFor="let wallet of wallets"
        class="w-full bg-black bg-opacity-5 hover:bg-opacity-10 h-[68px] py-1.5 px-4 rounded-xl flex-between-center gap-x-2.5 cursor-pointer text-base"
        (click)="connect(wallet.name)"
    >
        <div class="gap-x-2.5 flex-start-center">
            <img class="w-10 h-10 object-contain" [src]="wallet.img" [alt]="wallet.displayName" />
            <div class="font-bold" style="line-height: 1.2">{{wallet.displayName}}</div>
        </div>

        <span *ngIf="connectingWallet === wallet.name" nz-icon nzType="loading" class="text-2xl" nzTheme="outline"></span>
        <span *ngIf="connectingWallet !== wallet.name" nz-icon nzType="antd:v2-go-ahead" class="text-2xl" nzTheme="outline"></span>
    </div>
</div>
