import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConnectWalletModalComponent } from '@src/app/shared/components/connect-wallet-modal/connect-wallet-modal.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { BadgeComponent } from './components/badge/badge.component';
import { CopyComponent } from './components/copy/copy.component';
import { HexagonImgComponent } from './components/hexagon-img/hexagon-img.component';
import { SocialscanDirectiveModule } from './directives/socialscan-directive.module';
import { AddressPipe } from './pipes/address.pipe';
import { KMBPipe } from './pipes/kmb.pipe';
import { NumberSplitPipe } from './pipes/number-split.pipe';
import { OmitPipe } from './pipes/omit.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';

// TODO: New

@NgModule({
    declarations: [ConnectWalletModalComponent, HexagonImgComponent, BadgeComponent, KMBPipe, AddressPipe, SafeHtmlPipe, OmitPipe, NumberSplitPipe],
    imports: [
        CommonModule,
        RouterModule,
        NzInputModule,
        NzButtonModule,
        NzIconModule,
        NzTabsModule,
        NzSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NzSwitchModule,
        NzMessageModule,
        NzModalModule,
        NzPipesModule,
        NzToolTipModule,
        NzDrawerModule,
        NzImageModule,
        NzDropDownModule,
        NzPopoverModule,
        NgOptimizedImage,
        NzDrawerModule,
        NzMenuModule,
        NzSkeletonModule,
        NzCheckboxModule,
        NzAutocompleteModule,
        NzAvatarModule,
        NzCollapseModule,
        SocialscanDirectiveModule,
        NzBadgeModule,
        CopyComponent,
        PluralizePipe,

        // TODO: New
    ],
    exports: [
        RouterModule,
        NzInputModule,
        NzButtonModule,
        NzIconModule,
        NzTabsModule,
        NzSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NzSwitchModule,
        NzPipesModule,
        NzDrawerModule,
        NzImageModule,
        NzToolTipModule,
        NzPopoverModule,
        HexagonImgComponent,
        NgOptimizedImage,
        NzMenuModule,
        NzAvatarModule,
        BadgeComponent,
        KMBPipe,
        AddressPipe,
        SafeHtmlPipe,
        OmitPipe,
        SocialscanDirectiveModule,
        CopyComponent,
        PluralizePipe,
        NumberSplitPipe,
        // TODO: new
    ],
})
export class SharedModule {}
